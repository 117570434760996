import { Typography, TextField, Button } from '@mui/material'

import { Box } from '@mui/material'
import withAuth from './withAuth'
import { useState } from 'react'

const ProdDemoComponent = () => {
  const [widgetCode, setWidgetCode] = useState('')

  const handleWidgetCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWidgetCode(e.target.value)
  }

  const loadWidget = (): void => {
    const scripts = document.createRange().createContextualFragment(widgetCode)
    document.getElementById('widget-container')?.appendChild(scripts)
  }

  console.log(widgetCode)

  return (
    <Box>
      <Typography>Prod Demo</Typography>
      <TextField
        label="widget code"
        value={widgetCode}
        multiline
        rows={5}
        sx={{ width: '500px' }}
        onChange={handleWidgetCodeChange}
      />
      <Box id="widget-container" sx={{ mt: 2 }} />
      <Button variant="contained" onClick={loadWidget} sx={{ mt: 2 }}>
        Load Widget
      </Button>
    </Box>
  )
}

const ProdDemo = withAuth(ProdDemoComponent)

export default ProdDemo

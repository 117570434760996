import React from 'react'
import ReactDOM from 'react-dom/client'
import theme from './constants/theme'
import './styles/index.css'
import './styles/custom-toastify.css'
import GlobalStateProvider from './context/GlobalStateProvider'
import { ThemeProvider } from '@emotion/react'
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { fetchIsLoggedIn, fetchIsOnboarded } from './utils/loaders'
import { Page } from './constants'
import {
  fetchHasPageAccess,
  fetchIsEnabled,
  initFirebase,
  logout,
} from './utils'
import { get } from '@utils/api'
import ErrorBoundary from '@components/ErrorBoundary'
import * as Sentry from '@sentry/react'
import ProdDemo from '@components/admin/ProdDemo'
// import EditTemplateVariables from '@components/admin/EditTemplateVariables'

const ErrorPage = React.lazy(() => import('./components/Error'))
const Dashboard = React.lazy(() => import('./components/dashboard/index'))
const Onboarding = React.lazy(() => import('./components/onboarding/index'))
const Home = React.lazy(() => import('@components/dashboard/pages/home/Home'))
const Invite = React.lazy(() => import('@components/onboarding/Invite'))
const Unauthorized = React.lazy(() => import('@components/shared/Unauthorized'))
const ResetPassword = React.lazy(
  () => import('@components/shared/ResetPassword')
)
const NotFound = React.lazy(() => import('@components/NotFound'))
const NewOwnerInvite = React.lazy(
  () => import('@components/admin/NewOwnerInvite')
)
const PolicyOverrides = React.lazy(
  () => import('@components/admin/PolicyOverrides')
)
const Permissions = React.lazy(
  () => import('@components/dashboard/pages/Permissions')
)
const Login = React.lazy(() => import('@components/Login'))

const Settings = React.lazy(
  () => import('./components/dashboard/pages/Settings')
)
const ActionItems = React.lazy(
  () => import('./components/dashboard/pages/actionItems')
)
const Insights = React.lazy(
  () => import('./components/dashboard/pages/Insights')
)
const TerritoryInfo = React.lazy(
  () => import('@components/shared/TerritoryInfo')
)
const Locations = React.lazy(() => import('@components/shared/Locations'))

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://1b1a573ac71aafde11a198f3de5306bc@o4507592616640512.ingest.us.sentry.io/4508054381985792',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.jakiro\.ai/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

initFirebase()

export const checkAccess = async (page: Page) => {
  const hasAccess = await fetchHasPageAccess(page)
  if (!hasAccess) {
    return redirect('/unauthorized')
  }
  return null
}

const dashboardLoader = async () => {
  const user = await fetchIsLoggedIn()
  if (user === null) {
    return redirect('/login')
  }

  const isOnboarded = await fetchIsOnboarded()
  if (!isOnboarded) {
    return redirect('/onboarding')
  }
  return null
}

const onboardingLoader = async () => {
  const user = await fetchIsLoggedIn()
  if (user === null) {
    return redirect('/login')
  }
  const isOnboarded = await fetchIsOnboarded()
  if (isOnboarded) {
    return redirect('/')
  }
  return null
}

const isLoggedInLoader = async () => {
  const user = await fetchIsLoggedIn()
  if (user !== null) {
    return redirect('/')
  }
  return null
}

const actionItemsLoader = async () => {
  const user = await fetchIsLoggedIn()
  if (user === null) {
    return redirect('/login')
  }
  return get('/dashboard/action-items')
    .then((res) => res.json())
    .then((data) => data.actionItems)
}

const homeContentLoader = async () => {
  const user = await fetchIsLoggedIn()
  if (user === null) {
    return redirect('/login')
  }
  const isEnabled = await fetchIsEnabled(user.uid)
  if (!isEnabled) {
    await logout()
    return redirect('/login?disabled=true')
  }
  const hasHomeAccess = await fetchHasPageAccess(Page.Home)
  if (hasHomeAccess) {
    return get('/dashboard/action-items')
  }
  const hasActionItemAccess = await fetchHasPageAccess(Page.ActionItems)
  if (hasActionItemAccess) {
    return redirect('/action-items')
  }
  return redirect('/unauthorized')
}

const insightsLoader = async () => {
  const user = await fetchIsLoggedIn()
  if (user === null) {
    return redirect('/login')
  }
  return null
}

const locationsLoader = async () => {
  const user = await fetchIsLoggedIn()
  if (user === null) {
    return redirect('/login')
  }
  const hasAccess = await fetchHasPageAccess(Page.Locations)
  if (!hasAccess) {
    return redirect('/unauthorized')
  }
  return get('/dashboard/locations')
}

const territoryInfoLoader = async () => {
  const user = await fetchIsLoggedIn()
  if (user === null) {
    return redirect('/login')
  }
  const hasAccess = await fetchHasPageAccess(Page.TerritoryInfo)
  if (!hasAccess) {
    return redirect('/unauthorized')
  }
  return get('/dashboard/territory-info')
}

const permissionsLoader = async () => {
  const user = await fetchIsLoggedIn()
  if (user === null) {
    return redirect('/login')
  }
  const hasAccess = await fetchHasPageAccess(Page.Permissions)
  if (!hasAccess) {
    return redirect('/unauthorized')
  }
  return get('/dashboard/permissions')
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Dashboard />,
    loader: dashboardLoader,
    errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <Home />, loader: homeContentLoader },
      { path: Page.Settings, element: <Settings /> },
      {
        path: Page.ActionItems,
        element: <ActionItems />,
        loader: actionItemsLoader,
      },
      {
        path: Page.Insights,
        element: <Insights />,
        loader: insightsLoader,
      },
      {
        path: Page.TerritoryInfo,
        element: <TerritoryInfo />,
        loader: territoryInfoLoader,
      },
      {
        path: Page.Locations,
        element: <Locations />,
        loader: locationsLoader,
      },
      {
        path: Page.Permissions,
        element: <Permissions />,
        loader: permissionsLoader,
      },
    ],
  },
  {
    path: 'login',
    element: <Login />,
    loader: isLoggedInLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: 'onboarding',
    element: <Onboarding />,
    loader: onboardingLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: 'invite',
    element: <Invite />,
    // loader: onboardingLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: 'new-owner-invite',
    element: <NewOwnerInvite />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'prod-demo',
    element: <ProdDemo />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'policy-overrides',
    element: <PolicyOverrides />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: 'template-variables',
  //   element: <EditTemplateVariables />,
  //   errorElement: <ErrorPage />,
  // },
  {
    path: 'unauthorized',
    element: <Unauthorized />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'reset-password',
    element: <ResetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'home',
    loader: () => redirect('/'),
  },
  { path: '*', element: <NotFound /> },
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <GlobalStateProvider>
          <ToastContainer />
          <RouterProvider router={router} />
        </GlobalStateProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>
)

import * as React from 'react'

export enum ActionType {
  USER_LOGIN = 'USER_LOGIN',
}

interface UserLoginAction {
  type: ActionType.USER_LOGIN
  payload: User | null
}

interface GlobalState {
  user: User | null
}

type Action = UserLoginAction

const initialState: GlobalState = {
  user: null,
}

const globalReducer = (state: GlobalState, action: Action): GlobalState => {
  switch (action.type) {
    case ActionType.USER_LOGIN:
      return { ...state, user: action.payload }
    default:
      return state
  }
}

interface GlobalContextType {
  state: GlobalState
  dispatch: React.Dispatch<Action>
}

export const GlobalStateContext = React.createContext<GlobalContextType>({
  state: initialState,
  dispatch: () => {},
})

interface GlobalStateProviderProps {
  children: React.ReactNode
}

const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(globalReducer, initialState)

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateProvider
